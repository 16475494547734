import { normalizeUpperCase } from "@/utils/normalizeUpperCase";

export type OverviewByStatusType = {
  completed: number;
  draft: number;
  on_evaluator: number;
  on_supplier: number;
  overdue: number;
};

export type AssessmentOverviewType = {
  by_category?: { string: number };
  by_status?: OverviewByStatusType;
  total: number;
};

type OverviewByLicenseType = {
  basic: number;
  pro: number;
  strategic: number;
};

export class AssessmentOverview {
  public by_category;
  public by_status?: OverviewByStatusType;
  public total: number;

  constructor(data: AssessmentOverviewType) {
    this.by_category = data.by_category;
    this.by_status = data.by_status;
    this.total = data.total;
  }

  get overviewByStatus(): Array<StatusItem> {
    if (!this.by_status) return [];
    return Object.entries(this.by_status).map(
      ([title, val]) => new StatusItem({ title, val })
    );
  }

  get overviewByCategory(): Array<StatusItem> {
    if (!this.by_category) return [];
    return Object.entries(this.by_category).map(
      ([title, val]) => new StatusItem({ title, val })
    );
  }
}

class StatusItem {
  public title: string;
  public val: number;

  constructor(data: { title: string; val: number }, normalize?: boolean) {
    this.title = this.formatTitle(data.title, normalize);
    this.val = data.val;
  }

  private formatTitle(title: string, normalize?: boolean): string {
    return normalize ? normalizeUpperCase(title) : title;
  }
}
